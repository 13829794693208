const moment = require('moment');

export function getYears(startYear = 2023) {
  const currentYear = moment().year(); // Get the current year
  const yearsArray = [];

  // Loop from the current year down to 2023
  for (let year = currentYear; year >= startYear; year--) {
    yearsArray.push(year);
  }

  return yearsArray;
}

export function getMonths(fullName = true) {
  const months = [];

  for (let i = 0; i < 12; i++) {
      if (!fullName) {
        months.push({
          label: moment().month(i).format('MMM'),
          value: i + 1
        });
      } else {
        months.push({
          label: moment().month(i).format('MMMM'),
          value: i + 1
        });
      }
  }

  return months;
}

export function getMonthName(monthNumber) {
  if (!monthNumber) return 'Unknown';
  
  // Convert to number and subtract 1 since months in moment are 0-indexed
  const index = parseInt(monthNumber) - 1;
  
  // Check if the index is valid
  if (index >= 0 && index < 12) {
    return moment().month(index).format('MMMM');
  }
  
  return monthNumber; // Return the original value if not valid
}
